/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, takeLatest, select } from "redux-saga/effects";
import { interactionSlice } from "./slices";
import { IState } from "../../store/reducers";
import { SendError } from "api/send";
import { fetchInteraction, InteractionFetchError } from "api/fetchInteraction";

export interface InteractionFetchErrorInit {
  response: Response;
}

function* onLoadInteraction() {
  try {
    const interactionId = yield select(
      (state: IState) => state.interaction.interactionId
    );
    const { interaction, location } = yield call(
      fetchInteraction,
      interactionId
    );

    if (location) {
      window.location.href = location;

      return;
    }

    yield put(interactionSlice.actions.interactionOk(interaction));
  } catch (err) {
    const error = err as InteractionFetchError;

    if (error.json.error) {
      yield put(
        interactionSlice.actions.interactionNotOk({
          status: error.code,
          statusText: error.json.error,
        })
      );
    } else {
      yield put(
        interactionSlice.actions.interactionNotOk({
          status: error.code,
          statusText: error.statusText,
        })
      );
    }
  }
}

export function* watchInteractionSagas() {
  yield takeLatest(interactionSlice.actions.loadInteraction, onLoadInteraction);
}
