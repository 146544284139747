/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call } from "redux-saga/effects";
import { PUBLIC_URL } from "../env";

import { SendError } from "./send";

export interface InteractionFetchErrorInit {
  response: Response;
}

export class InteractionFetchError extends Error {
  private _response: Response;

  private _code: number;
  private _statusText: string;
  private _json: Record<string, any>;

  constructor(init: InteractionFetchErrorInit) {
    super();

    this._response = init.response;

    this._code = this._response.status;
    this._statusText = this._response.statusText;
    this._json = {};
  }

  get code() {
    return this._code;
  }

  get statusText() {
    return this._statusText;
  }

  get json() {
    return this._json;
  }

  tryJsonParse = async () => {
    try {
      this._json = await this._response.json();
    } catch (error) {
      return {};
    }
  };
}

export function* fetchInteraction(interactionId: string) {
  const url = `${PUBLIC_URL}/iapi/v2/interaction/${interactionId}/details`;
  const response = yield call(fetch, url);

  if (response.ok) {
    const location = response.headers.get("Location");
    const body = yield call([response, "json"]);

    return {
      interaction: body,
      location: location,
    };
  }

  const error = new InteractionFetchError({ response });
  yield call([error, "tryJsonParse"]);

  throw error;
}
